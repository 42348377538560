@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";
@import '~font-awesome/css/font-awesome.css';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@font-face {
    font-family: 'Metropolis';
    src: url('./assets/layout/fonts/Metropolis-Regular.otf');
  }

.ng-invalid {
    color: #d32f2f;
}

.state-icon-badge {
    color: #ffffff;

    &.state-A {
        background-color: #006CFF;
    }

    &.state-E {
        background-color: #FF0000;
    }

    &.state-I {
        background-color: #F26800;
    }
}

.state-badge {
    padding: 0.29em 0.6rem;
    font-weight: 400;
    cursor: pointer;
    color: #ffffff;
    border-radius: 11px;
    font-size: 0.85rem;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 500;

    &.state-A {
        background-color: #0052A1;
    }

    &.state-I {
        background-color: #E11F1D;
    }

    &.state-E {
        background-color: #FF0000;
    }

    &.state-LV {
        background-color: #121e3e;
    }

    &.state-V {
        color: #027A48;
        background-color: #ECFDF3;
    }

    &.state-PV {
        background-color: #F2F4F7;
        color: #344054;
    }

}

.p-accordion .p-accordion-header .p-accordion-header-link {
    background: #F9FAFB !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #F5F5F5 !important;
}

.splitButton {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
}

.p-accordion-header {
    border: 1px solid #D0D5DD;
    border-radius: 6px;
}

.p-datatable .p-datatable-thead>tr>th {
    background: #f7f7f7 !important;
}

.p-datatable {
    font-size: 0.86rem !important;
}

.p-fileupload-row>div:first-child {
    display: none !important;
}

.ec {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-size: 1.4rem;
    margin-right: 10px;
    position: relative;
}

.ec .icon {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    font-size: 1.4rem;
}

.ec-success {
    background: #D1FADF;
    color: #039855;
}

.ec-warning {
    background: #FEF0C7;
    color: #DC6803;
}

.p-button-xs {
    height: 1.75rem !important;
    width: 1.75rem !important;
}

.blue {
    color: #006CFF;
    font-size: 0.94rem;
}

.dark-blue {
    color: #0052A1;
}

.soft-gray {
    color: #667085;
}

.soft-green {
    color: #00D78D;
}

.dark-green {
    color: #027A48;
}

.dark-red {
    color: #B42318;
}

.back-lightgray {
    background-color: #fafafa;
    border-radius: 5px;
    border: 1px solid #ededed;
}

.back-white {
    background-color: #ffffff !important;
    border-radius: 5px;
}

.back-blue {
    background-color: #006CFF !important;
    color: #ffffff !important;
}
.back-violet {
    background-color: #7F56D9 !important;
    color: #ffffff !important;
}

.blue-rounded {
    background: #0052A1 !important;
}

.rechazo-rounded {
    background-color: #FFFFFF !important;
    color: #344054;
    border: 1px double #0052A1;
}

.tittle-aprobacion {
    color: #7F56D9;
}

.back-yellow {
    background-color: #FFA700 !important;
    color: #0052A1 !important;
    padding: 3px 14px;
}

.back-silver {
    background-color: #86C7FF !important;
    color: #0052A1 !important;
    padding: 3px 14px;
}

.btn-blue {
    max-width: 115px !important;
    border: 1px solid #006CFF !important;
    box-shadow: none !important;
}

.btn-red {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    color: red;
    background: #FFE7E6;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}

.btn-red:focus:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    color: red;
    background: #FFE7E6;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}

.back-cost {
    color: #FF0000;
    padding: 3px 5px;
    border-radius: 7px;
    border: 1px double #FF0000;
    background: #FFE7E6;
}

.hr-topneg {
    margin-top: -5px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.ql-editor {
    padding: 8px !important;
    font-size: 12px;
}

.pi-md {
    font-size: 1.4rem !important;
}

.p-button.p-button-icon-md {
    padding: 1rem !important;
}

.p-panel-title {
    line-height: 1.5 !important;
    font-size: 1rem !important;
}

.p-cdialog-right {
    margin-right: 0.05rem !important;
}

.p-cdialog-right .p-dialog-header {
    background: #F9FAFB !important;
    color: #101828;
    padding: 8px;
}


.p-cdialog-right .p-dialog-content {
    padding-top: 9px;
}

.p-fileupload .pi-plus::before {
    content: "\e944"
}

.p-fileupload .p-fileupload-choose {
    border-radius: 50%;
    background-color: #0052A1;
    color: #ffffff;
}

.p-fileupload .p-button-label {
    display: none !important;
}

.p-fileupload .p-button-icon-left {
    margin-right: 0rem !important;
}

.p-fileupload .p-button {
    min-width: auto !important;
    border-radius: 50%;
    background: #F2F4F7;
    color: #000;
    width: 35px !important;
}

.pendingRead {
    background-color: #ff0000 !important;
    padding: 15px !important;
    color: white !important;
}

.menuTabla {
    position: relative !important;
}

td p-tableCheckbox {
    margin-left: 9px;
}

.block-ui-cajachica {
    text-align: center;
}

.block-ui-wrapper.block-ui-main {
    background: rgba(0, 0, 0, 0.30) !important;
}

:host ::ng-deep button {
    margin-right: .25em;
}

:host ::ng-deep .p-component-overlay-enter .pi.pi-lock {
    animation: enter 150ms forwards;
}

:host ::ng-deep .p-component-overlay-leave .pi.pi-lock {
    animation: leave 150ms forwards;
}

@keyframes enter {
    from {
        color: transparent;
    }

    to {
        color: var(--text-color);
    }
}

@keyframes leave {
    from {
        color: var(--text-color);
    }

    to {
        color: transparent;
    }
}

.line {
    /* Gray/200 */
    background: #EAECF0;
    /* Inside auto layout */
    flex: none;
    margin-top: 0px;
    margin-bottom: 0px;
    align-self: stretch;
    flex-grow: 0;
}

// asociados negocio
.button-save {
    margin-right: 5px;
    margin-top: 5%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;
    box-sizing: border-box;
    font-style: normal;
    color: #344054;
}

.button-save :focus :hover {
    margin-right: 5px;
    margin-top: 5%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;
    box-sizing: border-box;
    font-style: normal;
    color: #344054;
}

.button-tab {
    width: 128px;
    height: 44px;
    margin-top: 5%;
    box-sizing: border-box;
    padding: 10px 16px;
    // gap: 8px;
    height: 40px;
    background: #0052A1;
    border: 1px solid #0052A1;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    flex: none;
    order: 3;
    flex-grow: 0;
}

.freeDay {
    color: #00d78d;
    padding: 7px;
    border: double 1px #00d78d;
    border-radius: 7px;
    background-color: #DFF8EB;
    font-weight: 500;
}

.shipments {
    color: #616261;
    font-weight: bold;
    padding: 8px;
    border: solid 1px #616261;
    border-radius: 7px;
}

.contenedor-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 10px 0px 0px 6px;
    width: 100%;
    left: 0px;
    top: 80px;
    background: #F9FAFB;
}

.title {
    height: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    color: #101828;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.contenedor-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.button-filter {
    margin-right: 5%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;
    box-sizing: border-box;
    font-style: normal;
    color: #344054;
}

@media (max-width: 991px) {
    .p-datatable.p-datatable-products .p-datatable-tbody>tr>td .text-center {
        text-align: left !important;
    }

    .hideResponsive {
        display: inline !important;
    }

   
}

@media (max-width: 1230px) {
    .blue {
        font-size: 0.86rem !important;
    }
}
.hideResponsive {
    display: none;
}

.pop-ecbox {
    padding: 20px 8px 20px 20px;
    align-content: flex-start;
    position: absolute;
    width: 100%;
}

.dropDawnStyle {
    .pop-ecbox {
        padding: 20px 8px 20px 20px;
        align-content: flex-start;
        // position: absolute;
        width: 100%;
    }
}

.p-tabmenu-nav {
    overflow-y: hidden;
    overflow-x: auto;
}

.pop-content {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 740px;
    box-sizing: content-box !important;
}

::-webkit-scrollbar {
    height: 9px;
    width: 9px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    border-radius: 6px !important;
}

::-webkit-scrollbar-thumb {
    background-color: #C1C1C1 !important;
    outline: 0px solid slategrey !important;
    border-radius: 6px !important;
    -webkit-box-shadow: inset 0 0 6px #C1C1C1 !important;
}

.link-tmp {
    color: #9747FF;
    font-weight: 600;
    margin: 16px 0px;
    display: flex;
    text-decoration: none;
}

.button-end {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 28px;
    gap: 8px;
    background: #7F56D9;
    border: 1px solid #7F56D9;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    height: 50px;
}

.p-fileupload-row {
    border: 2px solid #9E77ED;
    border-radius: 5px;
    padding: 5px;
}

.p-fileupload-row>div:last-child {
    max-width: 65px;
}

.content-central {
    width: 100%;
}

.border-central {
    border: none;
}

@media screen and (min-width: 1024px) {
    .content-central {
        padding: 0 15% !important;
    }

    .border-central {
        border: 1px solid #9E77ED !important;
    }
}

.switchStyle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    margin-top: -15px;
}

.divSwitch {
    border: 1px solid #d0d5dd;
    padding: 11px;
    border-radius: 7px;
    margin-left: 7px;
    margin-right: 7px;
}

.tab-scroll {
    overflow-y: scroll;
}

.tab-scrollsp {
    overflow-y: scroll;
}
.country-item {
    display: flex;
    align-items: center;
}
.content-central-option{
    width: 100%;
}
.title-text{
    color: #9747FF !important;
    line-height: 26px;
    font-weight: 600;
}
@media screen and (min-width: 1024px) {
    .content-central-option {
        padding: 0 25% !important;
    }

}
.button-purple{
    color: white;
    background-color: #9747FF;
    border: 1px solid #9747FF;
    height: 40px;

}
.button-white {
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    color: #344054;
    height: 40px;
}

.card-gray{
    border: 1px solid #D0D5DD;
    border-radius: 12px;
    margin-top: 20px;
    background: #F9FAFB;
    box-shadow: 0px 15px 10px -15px #444444;
}
.card-table{
    
        border: 1px solid black;
        border-collapse: collapse;
        border-radius: 10px;
}
    
.btn-lx{
    color: #ffffff;
}
.btn-lx .p-button-icon-left {
    font-size: 22px;
    padding-left: 10px;
}

//ESTILOS MODERNA
.p-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.left-top {
    background-color: #E11F1D;
    height: 35vh;
    text-align: center;
}
.left-top:before {
    content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 40px solid #E11F1D; /* Cambiado a border-top */
      border-right: 0px solid transparent;
      border-left: 50px solid transparent;
      border-bottom: 10px solid transparent; /* Cambiado a border-bottom transparente */
      top: 37vh; /* Cambiado a top: 100% */
      left: 9%;
      transform: translateX(-50%);
      z-index: 1;
}

.left-center {
    background-color: #fff;
    height: 32vh;
    text-align: center;
}
.left-botton {
    background-color: #E11F1D;
    height: 25vh;
    text-align: center;
}
.title-login{
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.90px;
}

.welcome-text{
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}
.login-button{
    background-color: #000;
    color: #fff;
    width: 190px !important;
}

.yellow-text{
    color: #FEBB2E;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
}
.white-text {
    color: #fff;
    font-size: 12px;

}
.center-container {
    height: 100vh; /* Ajusta la altura al 100% del viewport height */
  }

.height-bar{
    background-color: #E11F1D ;
}
.new-button{
    background-color: #0052A1;
    color: #fff !important;
}
.new-button:hover {
    background-color: #014a8d !important; /* Cambia este valor al color que desees para el hover */
    color: #ffffff !important; /* Puedes ajustar el color del texto al que desees */
}

.cancel-button{
    background-color: #E11F1D;
    color: #fff !important;
}
.cancel-button:hover {
    background-color: #c20a07 !important; /* Cambia este valor al color que desees para el hover */
    color: #ffffff !important; /* Puedes ajustar el color del texto al que desees */
}
.cancel-button-border{
    background-color: #fff;
    color: #E11F1D !important;
    border: 1px solid #E11F1D;
}
.cancel-button-border:hover {
    background-color: #c20a07 !important; /* Cambia este valor al color que desees para el hover */
    color: #ffffff !important; /* Puedes ajustar el color del texto al que desees */
}

.comment-button{
    border: 1px solid #000;
    background-color: #fff;
    color: #000 !important;
}
.comment-button:hover {
    background-color: #b1b0b0 !important; /* Cambia este valor al color que desees para el hover */
    color: #000 !important; /* Puedes ajustar el color del texto al que desees */
}


.inputSearch{
    height: 60px !important; 
    font-size: 11px;
     width: 100%;
     border-radius: 7px !important;
}
.inputSearch-repo{
    height: 48px !important; 
    font-size: 11px;
     width: 100%;
     border-radius: 7px !important;
}
.title-screen{
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.96px;
}
.p-float-label{
    label {
        margin-top: -14px;
        font-size: 12px;
        color: #656D76 !important;
        font-weight: 500;
    }
    input {
        &::placeholder {
            font-size: 12px;
            opacity: 1;
        }
    }
}
.p-datatable .p-datatable-thead > tr > th {
    background-color: #E11F1D !important;
    color: #fff !important;
}
.p-datatable .p-sortable-column .p-sortable-column-icon { 
    color: #fff !important;
}
.p-datatable>.p-datatable-wrapper {
    border-radius: 10px;
}

.card-border {
    border-radius: 10px;
    border: 1px solid #D8DEE4;
    background: #FFF;
}
.label-bold{
    font-weight: 600;
    font-size: 13px !important;
}


.custom-progress-bar {
.p-progressbar-value  {
    background-color: #0052A1 !important;  /* Cambia el color según tus preferencias */
}

}
.p-button-label {
    padding: 2px;
    font-size: 12px;
  }
  layout-topbar-logo-mobile{
    padding-left: 15rem !important;
    margin-left: 20rem;
  }
  .imagen-logo{
    background: url(assets/layout/images/caja_chica_1.png)!important;
  }
  
  @media only screen and (min-width: 1024px) {
    /* Desktop*/
    .layout-topbar-logo-mobile{
        display: none;
    }
    .login-button{
        background-color: #000;
        color: #fff;
        width: 190px !important;
    }
}
@media only screen and (min-width: 600px) and (max-width: 1023px) {
    /* Tablet */
    .layout-topbar-logo-desktop{
        display: none !important;
    }
    .login-button{
        background-color: #000;
        color: #fff;
        width: 190px !important;
    }
    .left-top:before {
        content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-top: 40px solid #E11F1D; /* Cambiado a border-top */
          border-right: 0px solid transparent;
          border-left: 50px solid transparent;
          border-bottom: 10px solid transparent; /* Cambiado a border-bottom transparente */
          top: 33vh; /* Cambiado a top: 100% */
          left: 25%;
          transform: translateX(-50%);
          z-index: 1;
    }
}
@media only screen and (max-width: 599px) {
    /* Celular*/
    .layout-topbar-logo-desktop{
        display: none !important;
    }
    .login-button{
        background-color: #000;
        color: #fff;
        width: 190px !important;
        margin-left: -0rem !important;
    }
    .div-login{
        //height: 18rem !important;
        padding-bottom: 35px !important;
    }
    .left-top {
        background-color: #E11F1D;
        height: 30vh;
        text-align: center;
    }
    .left-center {
        background-color: #fff;
        height: 27vh;
        text-align: center;
    }
    .left-botton {
        background-color: #E11F1D;
        height: 20vh;
        text-align: center;
    }
    .left-top:before {
        content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-top: 30px solid #E11F1D; /* Cambiado a border-top */
          border-right: 0px solid transparent;
          border-left: 40px solid transparent;
          border-bottom: 10px solid transparent; /* Cambiado a border-bottom transparente */
          top: 28vh; /* Cambiado a top: 100% */
          left: 9%;
          transform: translateX(-50%);
          z-index: 1;
    }
}
.title-dialog{
    font-size: 25px; 
    letter-spacing: -0.70px; 
    font-weight: 500;
}
.label-form{
    letter-spacing: -0.64px; 
    margin-left: 1.25rem;
    border-bottom: 5px;
}
.p-inputtext{
    letter-spacing: -0.50px !important;
}
.text-comments{
    border:solid #D8DEE4 1px; 
    border-radius: 8px; 
}
.comment-list{
    border-radius: 8px;
    border: solid #D8DEE4 1px;
    margin: 4px;
}
.text-comment{
    color: #A0A9B4;
    line-height: 15px;
    font-size: 12px;
}
.text-comment-data{
    color: #000;
    line-height: 15px;
    font-weight: 200;
    font-size: 12px;

}
.name-comment{
    line-height: 20px;
    font-weight: 600;
}
.input-border {
    border: none !important; padding-left: 0; padding-top: 6px;
}
.calendarData input{
    height: 60px; 
    font-size: 11px;
    width: 100%;
    border-radius: 7px !important;
    .p-calendar{
        width: 80% !important;
    }
}
.custom-dropdown .p-dropdown-items-wrapper .p-dropdown-item {
    font-size: 12px; /* Tamaño de fuente de las opciones */
    line-height: 1.5; /* Altura de línea de las opciones */
    padding: 10px; /* Espaciado interno de las opciones */
}
.custom-dropdown .p-dropdown-label {
    font-size: 12px; /* Tamaño de fuente del texto seleccionado */
    opacity: 1;
    margin-top: 15px !important;
    
}

.p-calendar .p-datepicker {
    max-width: 60% !important;
}
.ui-datepicker .ui-datepicker-calendar {
    width: 150px; /* Cambiar el ancho */
    
}
input[readonly] {
    background-color: #F3F3F3 !important; 
    opacity: 0.75 !important; 
  }
  input[disabled] {
    background-color: #F3F3F3 !important; 
    opacity: 0.75 !important; 
  }

span.p-element.p-dropdown-label[aria-disabled="true"] {
    background-color: #f3f3f3;  
}
#number-data .p-input-filled .p-inputgroup .p-float-label:last-child input {
    border-radius: 10px !important;
  }

#numberData .p-input-filled .p-inputgroup .p-float-label:last-child input{
height: 50px; 
font-size: 24px;
    width: 100%;
    font-weight: 600;
    border-radius: 10px !important;
}
.valido{
    background-color: #55e7b4;
}
.medio{
    background-color: gold;
}
.vencida{
    background-color: rgb(243, 72, 72);
}